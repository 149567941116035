import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import ability from '@/libs/acl/ability'
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import generelRoutes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...generelRoutes,

  ],
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) {
      if (to.path !== '/auth/login' || to.name !== 'auth-login') localStorage.setItem('toPath', to.path)
      if (to.name === 'auth-login') return next()
      return next({ name: 'auth-login' })
    }
    // If logged in => not authorized
    if (to.name === 'misc-not-authorized') return next()
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }


  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  if (!isUserLoggedIn()) pwaControl('login')
  else pwaControl('')

  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

function pwaControl(type) {
  console.log(router.currentRoute.name)
  if (router.currentRoute.name === 'app-detail') return true
  if (!window.matchMedia('(display-mode: standalone)').matches) {
    const routename = type ? `-${type}` : ''
    console.log("This is not running as pwa.");
    if (router.currentRoute.name !== `pwa-control${routename}`) router.push({
      name: `pwa-control${routename}`
    })
  }
}

export default router
