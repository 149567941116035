import axios from '@axios'

export const appStore = {
  namespaced: true,
  state: {
    appsData: [],
    myAppsData: [],
    searchData: [],
    appVersions: [],
    pagination: {},
    totalPages: 0,
    searchControl: true,
    page: 0,
    params: {},
    isUpdate: false,
  },
  mutations: {
    FETCH_APPS(state, data) {
      if (!data.data.data.length) state.searchControl = true
      state.appsData.push(...data.data.data)
      state.pagination = data.data.pagination
      state.totalPages = data.data.pagination.totalPages
    },
    FETCH_MY_APPS(state, data) {
      if (!data.data.data.length) state.searchControl = true
      state.myAppsData.push(...data.data.data)
      state.pagination = data.data.pagination
      state.totalPages = data.data.pagination.totalPages
    },
    FETCH_APP_VERSIONS(state, data) {
      if (!data.data.data.length) state.searchControl = true
      state.appVersions.push(...data.data.data)
      state.pagination = data.data.pagination
      state.totalPages = data.data.pagination.totalPages
    },
    UPDATE_IS_LOADING(state, status) {
      state.isUpdate = status
    },
    RESET_VERSIONS(state) {
      state.appVersions = []
      state.pagination = {}
      state.totalPages = 0
    },
    RESET_APPS(state) {
      state.appsData = []
      state.pagination = {}
      state.totalPages = 0
    },
    RESET_MY_APPS(state) {
      state.myAppsData = []
      state.pagination = {}
      state.totalPages = 0
    },
    CONTROL_SEARCH(state, status) {
      state.searchControl = status
    },
    SET_PAGE(state, page) {
      state.page = page
    }
  },
  actions: {
    searchApps(ctx, queryParams) {
      ctx.commit('RESET_APPS')
      ctx.commit('SET_PAGE', 0)
      if (queryParams.q.length < 1) {
        ctx.commit('CONTROL_SEARCH', false)
        return
      }
      ctx.commit('CONTROL_SEARCH', true)
      ctx.commit('UPDATE_IS_LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/store/apps', {
            params: queryParams,
          })
          .then(response => {
            resolve(response)
            ctx.commit('FETCH_APPS', response)
            ctx.commit('UPDATE_IS_LOADING', false)
          })
          .catch(error => reject(error))
      })
    },
    fetchApps(ctx, queryParams) {
      ctx.commit('RESET_MY_APPS')
      ctx.commit('CONTROL_SEARCH', false)
      ctx.commit('RESET_VERSIONS')
      ctx.commit('UPDATE_IS_LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/store/apps', {
            params: queryParams,
          })
          .then(response => {
            resolve(response)
            ctx.commit('FETCH_APPS', response)
            ctx.commit('UPDATE_IS_LOADING', false)
          })
          .catch(error => reject(error))
      })
    },
    searchMyApps(ctx, queryParams) {
      ctx.commit('RESET_MY_APPS')
      ctx.commit('SET_PAGE', 0)
      if (queryParams.q.length < 1) {
        ctx.commit('CONTROL_SEARCH', false)
        return
      }
      ctx.commit('CONTROL_SEARCH', true)
      ctx.commit('UPDATE_IS_LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/store/my/apps', {
            params: queryParams,
          })
          .then(response => {
            resolve(response)
            ctx.commit('FETCH_MY_APPS', response)
            ctx.commit('UPDATE_IS_LOADING', false)
          })
          .catch(error => reject(error))
      })
    },
    fetchMyApps(ctx, queryParams) {
      ctx.commit('RESET_APPS')
      ctx.commit('CONTROL_SEARCH', false)
      ctx.commit('RESET_VERSIONS')
      ctx.commit('UPDATE_IS_LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/store/my/apps', {
            params: queryParams,
          })
          .then(response => {
            resolve(response)
            ctx.commit('FETCH_MY_APPS', response)
            ctx.commit('UPDATE_IS_LOADING', false)
          })
          .catch(error => reject(error))
      })
    },
    fetchApp(ctx, { id }) {
      ctx.commit('RESET_APPS')

      ctx.commit('RESET_MY_APPS')
      ctx.commit('UPDATE_IS_LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .get(`/store/app/${id}`)
          .then(response => {
            resolve(response)
            ctx.commit('UPDATE_IS_LOADING', false)
          })
          .catch(error => reject(error))
      })
    },
    searchVersions(ctx, queryParams) {
      ctx.commit('SET_PAGE', 0)
      if (queryParams.q.length < 1) {
        ctx.commit('CONTROL_SEARCH', false)
        return
      }
      ctx.commit('CONTROL_SEARCH', true)
      ctx.commit('RESET_VERSIONS')
      return new Promise((resolve, reject) => {
        axios
          .get(`/store/app/${queryParams.id}/versions`, { params: queryParams })
          .then(response => {
            resolve(response)
            ctx.commit('FETCH_APP_VERSIONS', response)
          })
          .catch(error => reject(error))
      })
    },
    fetchVersions(ctx, queryParams) {
      ctx.commit('CONTROL_SEARCH', false)
      return new Promise((resolve, reject) => {
        axios
          .get(`/store/app/${queryParams.id}/versions`, { params: queryParams })
          .then(response => {
            resolve(response)
            ctx.commit('FETCH_APP_VERSIONS', response)
          })
          .catch(error => reject(error))
      })
    },
    resetDetailView(ctx) {
      ctx.commit('RESET_VERSIONS')
    },
    resetVersions(ctx) {
      ctx.commit('RESET_VERSIONS')
    },
    resetApps(ctx) {
      ctx.commit('RESET_APPS')
    },
    resetMyApps(ctx) {
      ctx.commit('RESET_MY_APPS')
    },
    resetInfinite(ctx, status) {
      ctx.commit('CONTROL_SEARCH', status)
    },
    incPage(ctx, page) {
      ctx.commit('SET_PAGE', page)
    },
    setStatus(ctx, status) {
      ctx.commit('UPDATE_IS_LOADING', status)
    },
    downloadAndroidBuild(ctx, { id }) {
      ctx.commit('UPDATE_IS_LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .post(`/build-jobs/${id}/download`)
          .then(response => {
            resolve(response)
            ctx.commit('UPDATE_IS_LOADING', false)
          })
          .catch(error => {
            ctx.commit('UPDATE_IS_LOADING', false)

            const { status, data } = error.response
            console.log(data.message)
            reject(error)
          })
      })
    },
    recordDownloadUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/store/download/${id}/record`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
