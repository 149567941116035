import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/acl'

import VueSweetalert2 from 'vue-sweetalert2'
// Import the CSS or use your own!
import 'sweetalert2/dist/sweetalert2.min.css'
import Icon from 'vue-awesome/components/Icon'

import Quill from 'quill'
import ImageResize from 'quill-image-resize'
import App from './App.vue'
import store from './store'
import router from './router'
require('@core/assets/fonts/feather/iconfont.css')
import VueObserveVisibility from 'vue-observe-visibility'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import LoadScript from 'vue-plugin-load-script';
Vue.use(LoadScript);
Vue.use(VueObserveVisibility)
// BSV Plugin Registration
Quill.register('modules/imageResize', ImageResize)

Vue.component('v-icon', Icon)
Vue.use(VueSweetalert2)
Vue.use(ToastPlugin)

Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Sentry.init({
  Vue,
  dsn: "https://e12c5c417faf427a8043f41a547ea906@o4503920164012032.ingest.sentry.io/4504083731316736",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "store.gameguru.ner", "tools.gameguru.net/app-store", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
