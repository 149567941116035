import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './modules/appModule'
import appConfig from './modules/appConfig'
import verticalMenu from './modules/verticalMenu'
import { auth } from './modules/authStore'
import { appStore } from './modules/appStore'
import { downloadLogStore } from './modules/downloadLogStore'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    appStore,
    downloadLogStore
  },
  strict: process.env.DEV,
})
