import axios from '@axios'

export const downloadLogStore = {
    namespaced: true,
    state: {
        data: [],
        pagination: {},
        params: {},
        isLoading: true,
    },
    mutations: {
        UPDATE_IS_LOADING(state, status) {
            state.isLoading = status
        },
    },
    actions: {
        fetchLogs(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/store/download/logs', { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
