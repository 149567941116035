export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      requiresAuth: true,
      pageTitle: 'Apps',
      action: 'read',
      resource: 'home',
      breadcrumb: [
        {
          text: 'Apps',
          active: true,
        },
      ],
    },
  },
  {
    path: '/my/apps',
    name: 'myApps',
    component: () => import('@/views/app/MyApps.vue'),
    meta: {
      requiresAuth: true,
      pageTitle: 'My Apps',
      action: 'read',
      resource: 'buildList',
      breadcrumb: [
        {
          text: 'My Apps',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/detail/:app',
    name: 'app-detail',
    component: () => import('@/views/app/AppDetail.vue'),
    meta: {
      requiresAuth: true,
      action: 'read',
      resource: 'buildList',
      pageTitle: 'App Detail',
      navActiveLink: 'home',
      breadcrumb: [
        {
          text: 'App Detail',
          active: true,
        },
      ],
    },
  },

  {
    path: '/download/logs',
    name: 'download-logs',
    component: () => import('@/views/downloadLogs/DownloadLogs.vue'),
    meta: {
      requiresAuth: true,
      pageTitle: 'Download Logs',
      action: 'read',
      resource: 'storeDownloadLogs',
      breadcrumb: [
        {
          text: 'Download Logs',
          active: true,
        },
      ],
    },
  },

  {
    path: '/auth/login',
    name: 'auth-login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      authRoute: true,
      resource: 'Auth',
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '*',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      publicRoute: true,
      layout: 'full',
    },

  },
  {
    path: '/misc/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/error/NotAuth.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      requiresAuth: true,
    },
  },
  {
    path: '/maintenance/',
    name: 'maintenance-mode',
    component: () => import('@/views/error/Maintenance.vue'),
    meta: {
      layout: 'full',
      requiresAuth: true,
      resource: 'buildList',
    },
  },
  {
    path: '/pwa/login',
    name: 'pwa-control-login',
    component: () => import('@/views/pwaControl.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      requiresAuth: false,
    },
  },
  {
    path: '/pwa',
    name: 'pwa-control',
    component: () => import('@/views/pwaControl.vue'),
    meta: {
      layout: 'full',
      resource: 'home',
    },
  },
]
